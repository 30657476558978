.about {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--second-color);
  &-content {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
  &-header {
    display: flex;
    align-items: center;
    justify-content: center;

    &__image {
      width: 200px;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__stats {
      width: 100%;
      display: flex;
      padding: 2rem;
      gap: 2rem;
      &-left {
        width: 60%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &-text {
          font-size: 0.9rem;
          font-weight: 400;
          color: #141414;
        }

        .btn {
          width: 32%;
          font-size: 0.9rem;
          text-align: center;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 1rem;
        &-item {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          width: 100%;

          h4 {
            font-size: 1rem;
            font-weight: 600;
            margin-right: 1rem;
          }

          .progress {
            width: 100%;
            height: 6px;
            background-color: #ccc;
            overflow: hidden;
            position: relative;
            &-bar {
              width: 0%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
  &-footer {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
    &-item {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin: 0 2rem;
      .icon {
        div {
          font-size: 32px !important;
          color: #141414;
        }
      }
      .content {
        display: flex;
        flex-direction: column;

        h5 {
          font-size: 1.5rem;
          font-weight: 800;
        }
        p {
          font-size: 0.8rem;
          font-weight: 300;
          color: #141414;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 1rem;
    &-header {
      flex-direction: column;
      width: 100%;
      &__stats {
        flex-direction: column;
        &-left {
          width: 100%;
          &-text {
            font-size: 0.8rem;
          }
          .btn {
            width: 100%;
          }
        }
        &-right {
          width: 100%;
          &-item {
            h4 {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
    &-footer {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      padding: 1rem 2rem;
      width: 100%;
      &-item {
        margin: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
      }
    }
  }
}
