.pricing {
  background-color: var(--second-color);
  &__container {
    padding: 1rem 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    gap: 1.5rem;
  }

  &__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    justify-content: space-between;
    gap: 1rem 0;
    align-items: center;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    &.active {
      gap: 1.8rem 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    &__active {
      position: absolute;
      top: 24px;
      left: 0;
      transform: rotate(270deg);
      font-size: 1rem;
      font-weight: 600;
      background-color: var(--first-color);
      color: #fff;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem 0 0 0.5rem;
    }
    &__image {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &__title {
      font-size: 1.25rem;
      font-weight: 600;
      color: #141414;
    }
    &__description {
      text-align: center;
    }
    &__price {
      font-size: 1.5rem;
      font-weight: 600;
      color: #141414;
    }
    &__button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: var(--first-color);
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      border: 1px solid var(--first-color);

      &:hover {
        background-color: #fff;
        color: var(--first-color);
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
