.aside {
  position: fixed;
  left: 0;
  top: 0;
  background-color: var(--body-color);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 2.5rem;
  width: 110px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;

  .nav__logo {
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    margin-bottom: 1.5rem;

    .nav__container {
      display: flex;
      justify-content: center;
      align-items: baseline;
    }
  }
  .nav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .nav-item {
      margin-bottom: 2rem;
      &.active {
        .nav-link {
          color: var(--first-color);
        }
      }
      .nav-link {
        div {
          font-size: 1rem !important;
          color: var(--title-color);
          font-weight: var(--font-medium);
          transition: all 0.3s ease-in-out;
          &:hover {
            color: var(--first-color);
          }
        }
      }
    }
  }

  .copyright {
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    color: hsl(245, 15%, 65%);
    font-size: 0.75rem;
  }
}
