.contact {
  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    gap: 2rem;

    &__info {
      width: 45%;
    }
    &__form {
      width: 55%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .form__group {
        display: flex;
        gap: 1rem;

        input,
        textarea {
          width: 100%;
          padding: 0.5rem 1rem;
          color: #000;
          outline: none;
          border-radius: 10px;
          border: none;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          resize: none;

          &::placeholder {
            color: #a9a9ac;
          }
        }

        button {
          width: 100%;
          padding: 0.5rem 1rem;
          color: #fff;
          outline: none;
          border-radius: 25px;
          border: none;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          background-color: var(--first-color);
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            background-color: darken(#ff6b6b, 10%);
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__content {
      flex-direction: column;
      gap: 1rem;

      &__info {
        width: 100%;
      }
      &__form {
        width: 100%;
      }
    }
  }
}
