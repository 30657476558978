.title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  position: relative;

  &::before {
    content: '';
    background: url('../../assets/dots-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    bottom: 20%;
    left: 0;
    width: 2.25rem;
    height: 2.25rem;
  }
}
