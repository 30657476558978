.resume {
  background-color: var(--second-color);
  &__content {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  &-timeline {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 1rem;
    background-color: #fff;
    width: 100%;
    height: fit-content;
  }
  &-timeline__item {
    padding: 0.5rem;
    display: flex;
    align-items: flex-start;
    position: relative;

    .absolute-icon {
      text-align: center;
      color: #f00;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .container {
      display: flex;
      flex-direction: column;
      padding: 0 2.25rem;
      width: 100%;
      &::before {
        content: '';
        position: absolute;
        bottom: 5%;
        left: 3%;
        width: 1px;
        height: 70%;
        background-color: #f00;
      }
    }

    &__date {
      color: #999;
      font-size: 0.8rem;
    }

    &__title {
      font-size: 1rem;
      font-weight: 600;
    }

    &__company {
      font-size: 0.9rem;
      font-weight: 400;
    }

    &__description {
      font-size: 0.8rem;
      color: #666;
    }
  }
  @media screen and (max-width: 768px) {
    &__content {
      flex-direction: column;
    }
  }
}
