/* Root CSS */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

/* ==================== Variables ==================== */
:root {
  /* colors */
  --first-color: hsl(353, 100%, 65%);
  --title-color: hsl(244, 24%, 26%);
  --text-color: hsl(244, 16%, 43%);
  --body-color: hsl(258, 60%, 98%);
  --container-color: #fff;

  /* ==================== Font and typography ====================*/
  /* .5rem = 8px | 1rem = 16px */
  --body-font: 'Rubik', sans-serif;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;

  /* ==================== Font weight  ====================*/
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;

  /* ==================== Box Shadow  ====================*/
  --shadow: 0px 5px 20px 0px rgba(69, 67, 96, 0.1);

  /* ==================== Border radius  ====================*/
  --border-radius: 20px;
}

/* ==================== Responsive Typography ==================== */
@media screen and (max-width: 1024px) {
  :root {
    --h1-font-size: 1.25rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* ==================== Base ==================== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

::-webkit-scrollbar-track {
  background: var(--body-color);
}

::-webkit-scrollbar-thumb {
  background: var(--title-color);
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  line-height: 1.7;
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semibold);
  line-height: 1.2;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

/* ==================== Layout ==================== */
.main {
  padding-left: 100px;
}

section {
  padding: 3rem 10rem;
  margin: 0 auto;
}

.container {
  max-width: 1080px;
  margin: 0 auto;
  padding: 0 15px;
}

.grid {
  display: grid;
}

.section {
  padding: 7rem 0 2rem 0;
}

.section__title {
  font-size: var(--h1-font-size);
  margin-left: 0.875rem;
  font-weight: var(--font-bold);
  position: relative;
  margin-bottom: 3.75rem;
}

.section__title::before {
  content: '';
  background: url(./assets/dots-bg.svg);
  height: 2.25rem;
  width: 2.25rem;
  position: absolute;
  left: -0.875rem;
  top: -0.875rem;
}

.btn {
  padding: 0.75rem 2rem;
  line-height: 1;
  border-radius: 1.875rem;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  color: #fff;
  display: inline-block;
  background-color: var(--first-color);
  font-weight: var(--font-bold);
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: hsl(353, 100%, 35%);
}

.icon-menu {
  display: none;
}

/* ==================== Media Query ==================== */

@media screen and (max-width: 1024px) {
  .main {
    padding-left: 0;
  }

  section {
    padding: 3rem 2rem;
  }

  .section {
    padding: 3rem 0 2rem 0;
  }

  .section__title {
    font-size: var(--h2-font-size);
  }

  .section__title::before {
    height: 1.5rem;
    width: 1.5rem;
    left: -0.5rem;
    top: -0.5rem;
  }

  .btn {
    padding: 0.5rem 1.5rem;
    border-radius: 1.25rem;
    font-size: var(--small-font-size);
  }

  .icon-menu {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 999;
    background-color: #fff;
    padding: 0.5rem;
    transform: rotate(0deg);
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  }

  .aside {
    left: -100%;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  }

  .aside.open {
    left: 0;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
  }

  .aside.open + .main > .icon-menu {
    transform: rotate(90deg);
  }
}
