.portfolio {
  padding-top: 2rem;
  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__filter {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-btn {
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      font-size: 1.1rem;
      color: #141414;
      transition: all 0.3s ease-in-out;
      &:hover {
        color: var(--first-color);
      }
      &.active {
        color: var(--first-color);
        text-decoration: wavy underline;
      }
    }
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
    gap: 1rem;

    &-item {
      display: flex;
      border-radius: 5px;
      flex-direction: column;
      gap: 1rem;
      cursor: pointer;
      position: relative;
      transition: all 0.3s ease-in-out;
      border-radius: 10px;
      &:hover {
        transform: scale(1.02);
      }

      &__image {
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.75);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        border-radius: 10px;
        z-index: 999;

        &:hover {
          opacity: 1;
        }

        &-content {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }

        &-title {
          width: 85%;
          margin: 0 auto;
          font-size: 0.8rem;
          text-align: center;
          font-weight: 600;
          padding: 1rem;
          color: #fefefe;
        }

        &-category {
          position: absolute;
          color: #fff;
          right: 10%;
          top: 0%;
          font-size: 1rem;
          background-color: #e84545;
          padding: 0.2rem 1rem;
          border-radius: 0rem 0rem 1rem 1rem;
        }

        &-link {
          position: absolute;
          left: 5%;
          bottom: 5%;
          color: #150050;
          background-color: #fff7e9;
          padding: 0.5rem;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-github {
          position: absolute;
          left: 20%;
          bottom: 5%;
          color: #fff7e9;
          background-color: #150050;
          padding: 0.5rem;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &__filter {
      width: 100%;
    }

    &__items {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
