.blog {
  background-color: var(--second-color);
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    margin: 0 auto;
    max-width: 1200px;
  }

  &-item {
    display: flex;
    margin-top: 1rem;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    border-radius: 5px;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    gap: 0.5rem;
    position: relative;
    cursor: pointer;
    &:hover {
      transform: translateY(-15px);
    }

    &__image {
      width: 100%;
      height: 200px;
      border-radius: 5px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    &__content {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      gap: 0.5rem;
    }
    &__title {
      font-size: 1.2rem;
      font-weight: 600;
      color: #333;
    }
    &__description {
      font-size: 0.9rem;
      color: #666;
    }
    &__footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 0.5rem;
    }
    &__author {
      font-size: 0.8rem;
      color: #666;
    }
    &__date {
      font-size: 0.8rem;
      color: #666;
    }
    &__category {
      position: absolute;
      top: 0;
      left: 5%;
      background: var(--first-color);
      color: #fff;
      padding: 0.2rem 0.8rem;
      border-radius: 0 0 10px 10px;
    }
  }

  @media screen and (max-width: 768px) {
    &__container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
