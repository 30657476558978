.home {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: relative;
  &__intro {
    max-width: 640px;
    text-align: center;
  }
  &__img {
    margin-bottom: 1.5rem;
    width: 200px;
    height: 200px;
  }
  &__title {
    font-size: var(--h2-font-size);
    font-weight: var(--font-bold);
  }
  &__subtitle {
    font-size: var(--smalller-font-size);
    font-weight: 400;
    margin: 0.8rem 0 1rem 0;
  }
  .header__socials {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    column-gap: 2rem;
    .header__social {
      color: var(--text-color);
      font-size: var(--h3-font-size);
      transition: all 0.3s ease-in-out;
      &:hover {
        color: hsl(43, 100%, 68%);
      }
    }
  }
  .shapes {
    .shape {
      position: absolute;
      &.s1 {
        top: 11%;
        left: 5%;
      }
      &.s2 {
        top: 18%;
        right: 10%;
      }
      &.s3 {
        bottom: 7%;
        left: 8%;
      }
      &.s4 {
        bottom: 21%;
        right: 10%;
      }

      &.s5 {
        top: 11%;
        left: 42%;
      }

      &.s6 {
        top: 20%;
        right: 31%;
      }

      &.s7 {
        bottom: 47%;
        left: 10%;
      }

      &.s8 {
        bottom: 18%;
        right: 30%;
      }

      &.s9 {
        bottom: 67%;
        right: 73%;
      }

      &.s10 {
        bottom: 28%;
        right: 71%;
      }

      &.s11 {
        bottom: 49%;
        right: 20%;
      }
    }
  }
  .home__scroll {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateX(-50%);
    row-gap: 2px;
    .scroll__title {
      font-size: var(--smaller-font-size);
      color: var(--text-color);
    }
    .scroll__area {
      width: 15px;
      height: 20px;
      border-radius: 35%;
      border: 1px solid var(--text-color);
      position: relative;
      .scroll__icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: var(--text-color);
        animation: scroll-icon 1.4s infinite;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .home__scroll {
      display: none;
    }

    .shapes {
      .shape {
        &.s1 {
          top: 10%;
          left: 24%;
        }
        &.s2 {
          top: 40%;
          right: 10%;
        }
        &.s3 {
          bottom: 4%;
          left: 8%;
        }
        &.s4 {
          bottom: 7%;
          right: 10%;
        }
        &.s5 {
          top: 8%;
          left: 50%;
        }
        &.s6 {
          top: 20%;
          right: 21%;
        }
        &.s7 {
          bottom: 47%;
          left: 10%;
        }
        &.s8 {
          bottom: 11%;
          right: 50%;
        }
        &.s9 {
          bottom: 70%;
          right: 80%;
        }
        &.s10 {
          bottom: 20%;
          right: 73%;
        }
        &.s11 {
          bottom: 27%;
          right: 20%;
        }
      }
    }
  }
}

@keyframes scroll-icon {
  0% {
    top: 25%;
  }
  50% {
    top: 75%;
  }
  100% {
    top: 25%;
  }
}
