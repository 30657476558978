.services {
  &-container {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    gap: 2rem;
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    &:hover {
      transform: translateY(-10px);
    }
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    &-container {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
  }
}
