.testimonials {
  position: relative;
  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    .swiper {
      padding: 3rem 0;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        background: #ccc;
        opacity: 1;
        transition: all 0.3s ease;
      }
      .swiper-pagination-bullet-active {
        background: var(--first-color);
        width: 40px;
        border-radius: 5px;
      }
    }
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    font-size: 1.1rem;
    font-weight: 600;
  }

  &__title {
    font-size: 0.9rem;
    font-weight: 400;
  }

  &__description {
    font-size: 0.875rem;
    font-weight: 400;
  }

  &__content {
    margin-top: 1rem;
    width: 100%;
    max-width: 600px;
    background-color: #fff;
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
